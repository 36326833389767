.footer {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  margin-left: auto;
  width: 50%;
}

.footer p {
  /* font-family: "Quicksand"; */
  text-align: center;
}

.footer-link {
  display: flex;
  width: 70px;
  margin-right: auto;
  margin-left: auto;
}

.footer-link a {
  margin: 5px;
}

.login-card {
  z-index: 100;
  max-width: 400px;
  padding: 20px;
  box-shadow: 0 1px 3px 3px #0000001a;
  border-radius: 4px;
  margin: 0px auto;
  background-color: white;
  overflow-y: auto;
}

.login-card h1 {
  font-size: 23px;
  /* font-family: "DoHyeon"; */
}

.login-card .div-item {
  margin: 10px 0 20px;
  text-align: left;
}

.login-card input {
  display: inline-block;
  min-height: 40px;
  width: calc(100% - 24px);
  font-size: 14px;
  line-height: 1.8;
  padding: 6px 12px;
  vertical-align: middle;
  background-color: transparent;
  color: #333;
  border: 1px solid #ddd;
}

.formButton,
.login-card button {
  font-size: 14px;
  font-weight: 200;
  text-transform: uppercase;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 5px;
  transition: 0.5s;
  color: #fff;
  background: #f4af24;
  cursor: pointer;
  text-decoration: none;
  margin: auto;
  width: 100%;
  border: none;
  max-width: 380px;
}
.login-card button:hover {
  color: black;
  box-shadow: 1px 1px 10px black;
}

.EDL-home-title {
  margin-bottom: 20px;
  /* font-family: "DoHyeon"; */
}

#spinner-modal {
  /* display: none; */
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.2) url("../assets/spinner.gif") 50% 50% no-repeat;
}

.container-Home {
  /* height: auto;
  overflow-y: visible; */
  /* height: 100vh; */
  /* width: inherit; */
  /* overflow: hidden; */
  /* height: 100vh; */
  /* overflow-y: scroll; */
}

#form-button {
  text-align: unset;
}
.login-card form p {
  text-align: justify;
}

@media only screen and (max-width: 650px) {
  .EDL-home-title p {
    font-size: 80%;
  }
  .EDL-home-title h1 {
    font-size: xx-large;
  }
}

@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

/* .container-Home {
  height: auto;
  overflow: unset;
  display: flex;
  min-height: 570px;
} */
body {
  /* font-family: "Kollectif"; */
}

/* .ticket-EDL-home-title {
    color: #000;
  } */

.container-Dashboard {
  margin-top: 70px;
  text-align: center;
  background-image: none;
}
p {
  text-align: justify;
}
.faq-title p,
.standard-select-div p {
  text-align: center;
}
.dashboard-content {
  text-align: justify;
  margin: auto;
  width: 80%;
  border-right: 2px #f4af24 solid;
  border-left: 2px #f4af24 solid;
  padding: 20px;
}

.dashboard-button {
  background-color: #f4af24;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-weight: 200;
  /* height: 250px; */
  letter-spacing: 1px;
  /* margin: auto; */
  padding: 8px;
  text-decoration: none;
  text-shadow: 1px 1px 3px gray;
  text-transform: uppercase;
  transition: 0.5s;
  margin-top: 10px;
  margin-bottom: 10px;
}
.dashboard-button:hover {
  color: black;
  box-shadow: 1px 1px 20px gray;
  background-color: #8f8f8f;
}
.dashboard-detail ul li {
  margin-bottom: 15px;
}

.standard-select-div {
  margin: 50px 0;
}

.standard-select-div select {
  cursor: pointer;
  background-color: white;
  border-radius: 50px;
  border: 2px solid gray;
  width: 25%;
  box-shadow: 1px 1px 10px #80808070;
}

.three-color-separator {
  padding-bottom: 1px;
  content: "";
  height: 1px;
  position: relative;
  bottom: -10px;
  background: linear-gradient(
    to right,
    rgba(237, 128, 52, 1) 0%,
    rgba(237, 128, 52, 1) 33%,
    rgba(254, 177, 35, 1) 33%,
    rgba(254, 177, 35, 1) 66%,
    rgba(33, 132, 205, 1) 66%,
    rgba(33, 132, 205, 1) 100%
  );
  width: 25%;
  margin: 0 auto;
}

.align-vertical-img {
  vertical-align: middle;
  border: 1px gray solid;
  border-radius: 3px;
  max-width: 100%;
}

.align-horizontal-img {
  display: block;
  margin: 10px auto 20px;
  border: 1px gray solid;
  border-radius: 3px;
  max-width: 100%;
}

/* img {
  vertical-align: middle;
  border: 1px gray solid;
  border-radius: 3px;
} */

.airtable-embed {
  display: inline-block;
  position: relative;
  width: 100%;
}
@font-face {
  font-family: "Kollectif";
  src: url(../fonts/kollektif/Kollektif.ttf); /* IE9 Compat Modes */
  font-display: swap;
}
@font-face {
  font-family: "DoHyeon";
  src: url(../fonts/Do_Hyeon/DoHyeon-Regular.ttf); /* IE9 Compat Modes */
  font-display: swap;
}

@media only screen and (max-width: 1160px) {
}

@media only screen and (max-width: 650px) {
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
}

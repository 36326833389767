.faq-content {
  text-align: justify;
  margin: 10px 50px;
  padding: 20px;
}

.faq-detail {
  border-bottom: 1px solid black;
}

.faq-content-h3 {
  color: #f4a24a;
  text-decoration: underline;
}

body {
  margin: 0;
  font-family: "Montserrat-medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Montserrat-medium";
  src: url(../fonts/Montserrat/static/Montserrat-Medium.ttf); /* IE9 Compat Modes */
  font-display: swap;
}